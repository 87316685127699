header.banner {
  @extend .bg-white;
  padding: $grid-gutter-height*1.5 0;
  .brand {
    display: block;
    img {
      @extend .img-fluid;
      @include media-breakpoint-down(sm) {
        max-width: 250px;
      }
    }
  }
  .navbar-toggler {
    display: flex;
    margin-left: auto;
    color: $brand-secondary;
  }

  .dropdown {
    .dropdown-toggle:after {
      display: none;
    }
    .dropdown-menu {
      margin-top: 0;
      font-size: $font-size-sm;
    }
    &:hover {
      &>.dropdown-menu {
        display: block;
      }
    }
  }
  .nav-primary {
    font-size: $font-size-sm;
    a {
      @extend .title-spacing;
      color: $brand-primary;
      text-transform: uppercase;
      &:hover,&.active {
        color: $link-color;
      }
    }
    .nav-item {
      &.active,&.current_page_parent {
        &>a {
          color: $link-color;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      a {
        text-align: center;
      }
      &.collapse {
        display: none;
        &.show {
          display: block;
        }
      }
    }
    @include media-breakpoint-up(md) {
      padding: 0;
      justify-content:flex-end;
      .navbar-nav {
        .menu-item {
          a {
            padding-left: $grid-gutter-width/3;
            padding-right: $grid-gutter-width/3;
          }
          &:first-child {
            a {
              padding-left: 0;
            }
          }
          &:last-child {
            a {
              padding-right: 0;
            }
          }
        }
      }
    }
    @include media-breakpoint-only(md) {
      font-size: $font-size-xs;
      .navbar-nav {
        .menu-item {
          a {
            letter-spacing: .1em;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-top: .25rem;
      font-size: $font-size-sm;
      .navbar-nav {
        .menu-item {
          a {
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      margin-top: .75rem;
    }
  }
}
