html {
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}
.wrap {
  padding-top: $grid-gutter-height*2;
  padding-bottom: $grid-gutter-height*2;
  min-height: 300px;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-height*3;
    padding-bottom: $grid-gutter-height*3;
    min-height: 500px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-height*4;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $grid-gutter-height*5;
  }
}
p,ul,ol {
  margin: 0 0 $grid-gutter-height*1.5;
}
b,strong {
  font-weight: $font-weight-bold;
}
h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  margin: $grid-gutter-height*2 0 $grid-gutter-height;
}
h1,h2,h3,
.h1,.h2,.h3 {
  @include media-breakpoint-up(md) {
    margin: $grid-gutter-height*2.5 0 $grid-gutter-height;
  }
  @include media-breakpoint-up(lg) {
    margin: $grid-gutter-height*3 0 $grid-gutter-height*1.5;
  }
}
h1,.h1 {
  font-size: $h1-font-size;
}
h2,.h2 {
  font-size: $h2-font-size;
}
h3,.h3 {
  font-size: $h3-font-size;
}
h4,.h4 {
  font-size: $h4-font-size;
}
h5,.h5 {
  font-size: $h5-font-size;
  @extend .smallcaps;
}
h6,.h6 {
  font-size: $h6-font-size;
  @extend .smallcaps;
}
.main {
  &>h2:first-child {
    margin-top: 0;
  }
  .page &,.single-post &  {
    @extend .col-md-10;
    @extend .offset-md-1;
    @extend .col-lg-8;
    @extend .offset-lg-2;
  }
}
.hero {
  @extend .bg-primary;
  position: relative;
  img {
    @extend .img-fluid;
    width: 100%;
    opacity: .8;
  }
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    height: 200px;
    img {
      max-width: none;
      height: 200px;
      width: auto;
    }
    .single-post &,.blog &  {
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .page-header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    text-align: center;
    @extend .text-shadow;
    h1 {
      margin: 0;
      @include media-breakpoint-down(sm) {
        font-size: $h2-font-size;
      }
    }
    h2 {
      @extend .smallcaps;
      font-size: $font-size-base;
      margin: $grid-gutter-height*2 0 0;
      @include media-breakpoint-down(sm) {
        margin-top: $grid-gutter-height;
      }
    }
    .blog & {
      p.excerpt,h1 {
        margin: 0 auto $grid-gutter-height;
        @include media-breakpoint-up(lg) {
          margin: 0 auto $grid-gutter-height*1.5;
        }
        @include media-breakpoint-up(xl) {
          margin: 0 auto $grid-gutter-height*2;
        }
      }
      h1 {
        font-size: $h3-font-size;
        @include media-breakpoint-up(md) {
          font-size: $h2-font-size;
        }
        @include media-breakpoint-up(lg) {
          width: 85%;
          font-size: $h1-font-size;
        }
        @include media-breakpoint-up(xl) {
          width: 80%;
        }
      }
      p.excerpt {
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
          width: 80%;
        }
        @include media-breakpoint-up(lg) {
          width: 60%;
        }
        @include media-breakpoint-up(xl) {
          width: 50%;
        }
      }

    }
  }
}
blockquote {
  @extend .bg-pink;
  padding: $grid-gutter-height;
  font-style: italic;
  strong {
    @extend .smallcaps;
    font-style: normal;
    display: block;
    text-align: right;
  }
  p:last-child {
    margin-bottom: 0;
  }

}
