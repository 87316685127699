.content-info {
  @extend .bg-primary;
  @extend .block-padding-sm;
  font-size: $font-size-sm;
  text-align: center;
  color: $brand-secondary;
  .nav-social {
    margin: 0 0 $grid-gutter-width*2;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 $grid-gutter-width;
      a {
        display: block;
        font-size: $font-size-h3;
        @include media-breakpoint-up(md) {
          font-size: $font-size-h2;
        }
      }
    }
  }
  p {
    margin: 0;
  }
}
