@charset 'UTF-8';
/* Slider */

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slides {
  visibility: hidden;
  display: none;
  &.slick-initialized {
    visibility: visible;
    display: block;
  }
}
.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 0;
    @include media-breakpoint-up(md) {
      bottom: $grid-gutter-height/2;
    }
    @include media-breakpoint-up(lg) {
      bottom: $grid-gutter-height;
    }

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 1rem;
    height: 1rem;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 1rem;
    height: 1rem;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
}
.slick-dots li button:before
{
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f111";
    font-size: $font-size-sm;
    line-height: 1;

    opacity: .75;

    position: absolute;
    top: 0;
    left: 0;

    width: 2rem;
    height: 2rem;

    text-align: center;

    color: $white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    font-weight: 900;
}
.slides {
//   margin: 0 0 -6px;
  margin: 0;
  .slick-slide {
    &>div {
      position: relative;
      text-align: center;
      &>div {
        display: block !important;
        background-color: $black;
      }
      img {
        @extend .img-fluid;
        width: 100%;
        opacity: .65;
      }
      article {
        position: absolute;
        width: 100%;
        left: 0;
        top: 30%;
        transform: translateY(-30%);
        color: $white;
        @extend .text-shadow;
        text-align: center;
        font-weight: $font-weight-light;
        h1 {
          font-size: $h4-font-size;
          margin: $grid-gutter-height 0;
          line-height: 1;
        }
        p {
          font-size: $font-size-sm;
          margin: 0;
          @extend .title-spacing;
          text-transform: uppercase;
          line-height: 1.2;
        }
        @include media-breakpoint-up(md) {
          top: 50%;
          transform: translateY(-50%);
          h1 {
            font-size: $h2-font-size;
            margin: $grid-gutter-height*1.5 0;
          }
          p {
            font-size: $font-size-base;
          }
        }
        @include media-breakpoint-up(lg) {
          h1 {
            font-size: $h1-font-size;
          }
        }
      }
    }
  }
}
.events-slider {
  .slick-dots {
    bottom: -$grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      bottom: -$grid-gutter-height*2.5;
    }
    @include media-breakpoint-up(lg) {
      bottom: -$grid-gutter-height*3.5;
    }
  }
}
