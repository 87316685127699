.btn {
  @extend .title-spacing;
  cursor: pointer;
  font-family: $font-family-base;
  min-width: 170px;
  text-transform: uppercase;
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border: 1px solid $btn-primary-border;
  &:hover {
    border-color: $btn-primary-border;
    background-color: $btn-primary-color;
    color: $white;
  }
}
