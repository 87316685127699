.single-post {
  .hero {
    a {
      color: $white;
    }
  }
  .hentry {
    footer {
      .tags {
        margin-top: $grid-gutter-height*3;
        font-size: $font-size-sm;
        em {
          @extend .smallcaps;
          font-style: normal;
        }
      }
    }
  }
  #comments {
    margin-top: $grid-gutter-height*3;
    padding-top: $grid-gutter-height*2;
    border-top: 1px solid rgba($black,.1);
    #reply-title,h2 {
      margin-top: 0;
    }
    textarea {
      @extend .form-control;
      border: 1px solid $border-color;
    }
  }
  .block-comments {
    @extend .bg-sage;
    @extend .block-padding-sm;
    #comments {
      @extend .col-md-10;
      @extend .offset-md-1;
      @extend .col-lg-8;
      @extend .offset-lg-2;
    }
  }
}
time.updated {
  @extend .smallcaps;
  display: block;
  font-size: $font-size-sm;
  color: rgba($black,.6);
  margin-bottom: $grid-gutter-height;
  .single-post & {
    margin-bottom: $grid-gutter-height*2;
  }
}

.blog-list {
  @extend .row;
  margin-bottom: $grid-gutter-height*3;
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-height*4.5;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-height*5;
  }
  header {
    @extend .col-12;
    @extend .col-md-5;
    @extend .col-lg-3;
    @extend .col-xl-4;
    .entry-title {
      margin: 0 0 .5rem;
      line-height: 1.1;
      font-size: $h3-font-size;
      a {
        color: $body-color;
        text-decoration: none;
        &:hover {
          color: $link-color;
        }
      }
    }

  }
  .entry-summary {
    @extend .col-12;
    @extend .col-md-7;
    @extend .col-lg-6;
  }
  footer {
    @extend .col-12;
    @extend .col-md-7;
    @extend .offset-md-5;
    @extend .col-lg-3;
    @extend .col-xl-2;
    @extend .offset-lg-0;
    @include media-breakpoint-up(lg) {
      text-align: right;
      .btn {
        min-width: 0;
      }
    }
  }
}
