// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-group {
  label {
    @extend .sr-only;
  }
}
.form-control {
  font-family: $font-family-sans-serif;
  &::-webkit-input-placeholder {
	  font-style: italic;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  font-style: italic;
	}
	&::-moz-placeholder {  /* Firefox 19+ */
	  font-style: italic;
	}
	&:-ms-input-placeholder {
	  font-style: italic;
	}

  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
#gform_wrapper_2 {
  form {
    @extend .row;
    margin-left: 0;
    margin-right: 0;
  }
  .gform_heading,.gform_body {
    @extend .col-12;
  }
  .gform_description{
    text-align: center;
    margin-bottom: $grid-gutter-width;
  }
  .gform_footer {
    @extend .col-12;
    @extend .col-md-4;
    @extend .offset-md-4;
    text-align: center;
    margin-top: 0;
    input[type=submit] {
      @extend .btn;
      width: 93%;
      margin: 0 auto;
    }
  }
  .gform_fields {
    @extend .row;
    .gfield {
      @extend .form-group;
      @extend .col-12;
      @extend .col-md-4;
      margin-bottom: $grid-gutter-width/2;
      margin-top: 0;
      label {
        @extend .sr-only;
      }
      input[type=text],input[type=email] {
        @extend .form-control;
      }
    }
  }
}
.ctct-form-wrapper {
  .ctct-form {
    @extend .row;
    .ctct-button {
      @extend .d-none;
      color: $white;
    }
    .ctct-form-description {
      @extend .col-12;
    }
    .ctct-form-field-text,.ctct-form-field-email,.ctct-form-field-submit {
      @extend .form-group;
      @extend .col-12;
      @extend .col-md-6;
      @extend .col-lg-3;
      margin-bottom: $grid-gutter-width;
      label {
        @extend .sr-only;
      }
      input[type=text],input[type=email] {
        @extend .form-control;
      }
      input[type=submit] {
        @extend .btn;
        width: 100%;
      }
    }
    .ctct-disclosure {
      @extend .sr-only;
    }
  }
  .ctct-message {
    @extend .col-12;
    @extend .col-lg-8;
    @extend .offset-lg-2;
    @extend .bg-pink;
    border: none;
    &.success {
      @extend .bg-pink;
      color: $brand-primary;
    }
    &.error {
      @extend .bg-pink;
      color: $brand-primary;
    }
  }
}
#gform_wrapper_1 {
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), textarea {
    @extend .form-control;
    background-color: $pink;
    &:focus {
      background-color: $pink;
    }
  }
  .input.button, input[type=submit] {
    @extend .btn;
  }
}
