.block {
  @extend .block-padding;
}
.block-testimonial {
  @extend .bg-secondary;
  text-align: center;
  font-size: $h5-font-size;
  font-weight: $font-weight-light;
  line-height: 1.25;
  cite {
    margin-top: $grid-gutter-height*1.5;
    font-size: $font-size-sm;
    font-weight: $font-weight-base;
    font-style: normal;
    display: block;
  }
  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
    cite {
      margin-top: $grid-gutter-height*2;
    }
  }
  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
    cite {
      margin-top: $grid-gutter-height*3;
      font-size: $font-size-base;
    }
  }
}
.block-callouts {
  .home & {
    @include media-breakpoint-up(md) {
      background: url(../images/bg-callout.png) repeat-x 50% 55%;
    }
  }
  .callout {
    margin-bottom: $grid-gutter-width;
    .callout-img {
      display: block;
      margin: 0 auto $grid-gutter-height;
      img {
        @extend .img-fluid;
      }
    }
    h2 {
      margin: $grid-gutter-height 0;
    }
    .btn {
      margin-top: $grid-gutter-height;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      .btn {
        width: 100%;
      }
    }
  }
}
.block-events {
  @extend .bg-sage;
  h1 {
    @extend .title-block
  }
  .event-item {
    margin-top: $grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-height*3;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-height*4;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .event-img {
    @extend .col-12;
    @extend .col-md-5;
    margin-bottom: $grid-gutter-height;
    img {
      @extend .img-fluid;
    }
  }
  .event-detail {
    @extend .col-12;
    @extend .col-md-7;
    date,.location {
      @extend .title-spacing;
      font-weight: $font-weight-bold;
      color: $green;
      text-transform: uppercase;
      display: block;
      margin-bottom: $grid-gutter-height;
    }
    date {
      margin-bottom: $grid-gutter-height/2;
    }
    h2 {
      margin: $grid-gutter-height 0;
    }
    .btn {
      margin-top: $grid-gutter-height;
    }
    @include media-breakpoint-down(sm) {
      .btn {
        width: 100%;
      }
    }
  }
}
.block-newsletter {
  @extend .bg-secondary;
  text-align: center;
  h3 {
    margin:0 0 $grid-gutter-height;
  }
  p {
    margin-bottom: $grid-gutter-height*1.5;
  }
  @include media-breakpoint-down(sm) {
    .btn {
      width: 100%;
    }
  }
}
.block-video {
  @extend .bg-black;
  text-align: center;
  h1 {
    @extend .title-block
  }
}

.block-blog {
  @extend .bg-pink;
  h1 {
    @extend .title-block
  }
  .viewall {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
      min-width: 360px;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
  }

}

.blog-pagination {
  @extend .bg-pink;
  @extend .block-padding-sm;
  .nav-links {
    @extend .row;
    .nav-previous,.nav-next {
      @extend .col-12;
      @extend .col-md-6;
      text-align: center;
      a {
        @extend .btn;
        min-width: 260px;
        @include media-breakpoint-up(md) {
//           min-width: 260px;
        }
      }
    }
    .nav-next {
      margin-top: $grid-gutter-height;
    }
    @include media-breakpoint-up(md) {
      .nav-previous {
        text-align: left;
      }
      .nav-next {
        text-align: right;
        margin-top: 0;
      }
    }

  }
}
