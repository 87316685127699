// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width:     1.5rem;
$grid-gutter-height:    1rem;

// COLORS
$red:                   #6F1B34;
$red-alt:               #D77E72;
$pink:                  #F8E5E2;
$green:                 #77825C;
$sage:                  #BBC0AD;
$sand:                  #D5AD79;

$white:                 #fff;
$black:                 #3D3D3D;
$black-true:            #000;
$brand-primary:         $red;
$brand-secondary:       $red-alt;

$body-bg:               $white;
$body-color:            $black;
$border-color:          $black;

// Links
$link-color:            $brand-secondary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;

// FONTS
$font-family-sans-serif: 'Source Sans Pro', Arial, "Helvetica Neue", sans-serif;
// $font-family-alt:        'Alegreya Sans', Arial, "Helvetica Neue", sans-serif;
$font-family-base:       $font-family-sans-serif;

$font-size-base: 1rem;      // 16px
$font-size-xs:   .75rem;    // 12px
$font-size-sm:   .875rem;   // 14px
$font-size-lg:   1.25rem;   // 20px

$h1-font-size: 3rem;        // 48px
$h2-font-size: 2.25rem;     // 36px
$h3-font-size: 2rem;        // 32px
$h4-font-size: 1.75rem;     // 28px
$h5-font-size: 1.5rem;      // 24px
$h6-font-size: 1rem;        // 18px
// $h6-font-size: 1.25rem;     // 20px

$font-weight-normal:  normal;
$font-weight-base:    $font-weight-normal;
$font-weight-light:   300;
$font-weight-bold:    600;

$headings-font-family:   $font-family-base;
$headings-font-weight:   300;
$headings-line-height:   1.2;
$headings-color:         inherit;



// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  $grid-gutter-width;
$btn-padding-y:                  $grid-gutter-height;
$btn-line-height:                1;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-primary-color:              $black;
$btn-primary-bg:                 transparent;
$btn-primary-border:             $black;

$btn-green-color:                $green;
$btn-green-border:               $green;

$btn-white-color:                $white;
$btn-white-border:               $white;

$btn-secondary-color:            $btn-primary-color;
$btn-secondary-bg:               $btn-primary-bg;
$btn-secondary-border:           $btn-primary-border;

$btn-padding-x-sm:               $btn-padding-x;
$btn-padding-y-sm:               $btn-padding-y;

$btn-padding-x-lg:               $btn-padding-x;
$btn-padding-y-lg:               $btn-padding-y;

$btn-border-radius:              0;
$btn-border-radius-lg:           $btn-border-radius;
$btn-border-radius-sm:           $btn-border-radius;

$btn-transition:                 all .2s ease-in-out;


$dropdown-link-color:            $brand-primary;
$dropdown-link-hover-color:      $link-color;
$dropdown-link-hover-bg:         transparent;

$dropdown-link-active-color:     $link-color;
$dropdown-link-active-bg:        transparent;

// Forms

$input-padding-x:                $btn-padding-x;
$input-padding-y:                $btn-padding-y;
$input-line-height:              1;

$input-bg:                       $white;

$input-color:                    $brand-primary;
$input-border-color:             $input-bg;
$input-btn-border-width:         0; // For form controls and buttons
$input-box-shadow:               none;

$input-border-radius:            0;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $input-bg;
$input-border-focus:             lighten($brand-primary, 25%);
$input-box-shadow-focus:         $input-box-shadow, rgba($input-border-focus, .6);
$input-color-focus:              $input-color;

$input-color-placeholder:        $brand-secondary;

$input-padding-x-sm:             $input-padding-x;
$input-padding-y-sm:             $input-padding-y;

$input-padding-x-lg:             $input-padding-x;
$input-padding-y-lg:             input-padding-y;

$input-height:                   48px;
$input-height-lg:                $input-height;
$input-height-sm:                $input-height;
