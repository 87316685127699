.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: $black;
  color: $white;
}
.bg-primary {
  background-color: $brand-primary;
  color: $white;
}
.bg-secondary {
  background-color: $brand-secondary;
  color: $white;
}
.bg-pink {
  background-color: $pink;
}
.bg-primary,.bg-secondary {
  .btn {
    color: $btn-white-color;
    border: 1px solid $btn-white-color;
    &:hover {
      border-color: $brand-primary;
      background-color: $brand-primary;
      color: $white;
    }
  }
}
.bg-green {
  background-color: $green;
  color: $white;
}
.bg-sage {
  background-color: $sage;
  color: $white;
}
.bg-sage,.bg-green {
  .btn {
    color: $btn-green-color;
    border: 1px solid $btn-green-color;
    &:hover {
      border-color: $btn-green-color;
      background-color: $btn-green-color;
      color: $white;
    }
  }
}
.title-block {
  margin: 0 0 1.5em;
}
.text-shadow {
  text-shadow: 1px 1px rgba($black,.25);
}
.title-spacing {
  letter-spacing: .15em;
}
.title-spacing-sm {
  letter-spacing: .1em;
}
.smallcaps {
  @extend .title-spacing;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  text-transform: uppercase;
}
.block-padding {
  padding: $grid-gutter-height*3 0;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-height*4 0;
  }
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*5 0;
  }
  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-height*6 0;
  }
}
.block-padding-sm {
  padding: $grid-gutter-height*1.5 0;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-height*2 0;
  }
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*2.5 0;
  }
  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-height*3 0;
  }
}
